h4 {
    font-size: 2rem;
    line-height: 30px;
}

.work {
    margin-top: 15rem;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .work {
        margin-top: 13rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .work {
        margin-top: 12rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .work {
        margin-top: 10rem;
    }
}

@media (max-width: 479px) {
    .work {
        margin-top: 8rem;
    }
}

.work .work_header {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    padding-left: 5rem;
}

.work .work_header span {
    display: flex;
}

.work .work_header span h2 {
    font-family: Cyborg;
    font-weight: 900;
    margin: 0 .5rem;
    padding: 0;
}

.work .projects {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 3rem 0 7rem;
    padding: 0 5rem;
}

.work .projects .project_item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 6rem 0;
    width: 100%;
}


@media (min-width: 760px) and (max-width: 1024px) {
    .work .projects .project_item {
        flex-direction: column;
        margin: 5rem 0rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .work .projects .project_item {
        flex-direction: column;
        margin: 3rem 0rem;
    }
}

@media (max-width: 479px) {
    .work .projects .project_item {
        flex-direction: column;
        margin: 2rem 0rem;
    }
}


.work .projects .project_item.reverse {
    flex-direction: row-reverse;
}

@media (min-width: 760px) and (max-width: 1024px) {
    .work .projects .project_item.reverse {
        flex-direction: column;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .work .projects .project_item.reverse {
        flex-direction: column;
    }
}

@media (max-width: 479px) {
    .work .projects .project_item.reverse {
        flex-direction: column;
    }
}

.work .projects .project_item .project_item_img {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 60%;
}

@media (min-width: 760px) and (max-width: 1024px) {
    .work .projects .project_item .project_item_img {
        width: 100%;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .work .projects .project_item .project_item_img {
        width: 100%;
    }
}

@media (max-width: 479px) {
    .work .projects .project_item .project_item_img {
        width: 100%;
    }
}

.work .projects .project_item .project_item_img img {
    border-radius: 15px;
    box-shadow: 0 60px 123px -25px hsla(225, 2%, 62%, .42), 0 35px 75px -35px hsla(225, 2%, 62%, .08);
    -webkit-filter: blur(0);
    filter: blur(0);
    height: 398px;
    opacity: 1;
    -webkit-transform: perspective(800px) rotateY(25deg) scale(.8) rotateX(10deg);
    transform: perspective(800px) rotateY(25deg) scale(.8) rotateX(10deg);
    transition: all .6s ease;
    width: 700px;
}

.work .projects .project_item .project_item_img img:hover {
    box-shadow: 0px 19px 67px -38px rgba(155, 156, 159, 0.8);
    transform:
        perspective(800px) rotateY(-15deg) translateY(-50px) rotateX(10deg) scale(1);
    filter: blur(.2px);
    opacity: 1;
}

@media (min-width: 480px) and (max-width: 759px) {
    .work .projects .project_item .project_item_img img {
        width: 500px;
        height: 284px;
    }
}

@media (max-width: 479px) {
    .work .projects .project_item .project_item_img img {
        width: 350px;
        height: 199px;
    }
}

.work .projects .project_item.reverse .project_item_img img {
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
    -webkit-transform: perspective(800px) rotateY(-25deg) scale(.8) rotateX(10deg);
    transform: perspective(800px) rotateY(-25deg) scale(.8) rotateX(10deg);
    transition: all .6s ease;
}

.work .projects .project_item.reverse .project_item_img img:hover {
    box-shadow: 0px 19px 67px -38px rgba(155, 156, 159, 0.8);
    transform:
        perspective(800px) rotateY(15deg) translateY(50px) rotateX(10deg) scale(1);
    filter: blur(.2px);
    opacity: 1;
}

.work .projects .project_item .project_item_detail {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    width: 40%;
}

@media (min-width: 760px) and (max-width: 1024px) {
    .work .projects .project_item .project_item_detail {
        width: 100%;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .work .projects .project_item .project_item_detail {
        width: 100%;
    }
}

@media (max-width: 479px) {
    .work .projects .project_item .project_item_detail {
        width: 100%;
    }
}

.work .projects .project_item .project_item_detail h4 {
    border: 3px solid #14e956;
    box-shadow: 6px 6px 0 0 #14e956;
    color: #14e956;
    font-family: Cyborg;
    font-weight: 700;
    font-weight: 900;
    margin-bottom: 3rem;
    padding: .7rem 1.1rem;
    text-transform: uppercase;
}


@media (min-width: 480px) and (max-width: 759px) {
    .work .projects .project_item .project_item_detail h4 {
        font-size: 1.8rem;
    }
}

@media (max-width: 479px) {
    .work .projects .project_item .project_item_detail h4 {
        font-size: 1.5rem;
    }
}

.work .projects .project_item .project_item_detail p {
    font-size: 18px;
    margin: 0 0 3.5rem;
    text-align: center;
}


@media (min-width: 480px) and (max-width: 759px) {
    .work .projects .project_item .project_item_detail p {
        font-size: 17px;
    }
}

@media (max-width: 479px) {
    .work .projects .project_item .project_item_detail p {
        font-size: 16px;
    }
}

.work .projects .project_item .project_item_detail p span {
    color: #14e956;
}

.work .projects .project_item .project_item_detail .button_wrapper {
    align-items: center;
    display: flex;
    height: -webkit-max-content;
    height: max-content;
    justify-content: center;
}

.work .projects .project_item .project_item_detail .button_wrapper .btn {
    padding: .5rem .9rem;
    text-transform: lowercase;
    font-family: "Cyborg";
    font-weight: 900;
    font-size: 1.1rem;
    background: #14e956;
    background-color: #14e956;
    background-image: linear-gradient(315deg, #14e956 0%, #72f29a 100%);
    border: none;
    border-radius: 7px;
    box-shadow: 9px 7px 32px -11px rgba(20, 233, 86, 0.74);
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: none;
    position: relative;
}


.work .projects .project_item .project_item_detail .button_wrapper .btn::before {
    border: 3px solid #72f29a;
    border-radius: 7px;
    box-shadow: 0 0 60px rgba(20, 233, 86, .64);
    content: "";
    left: 50%;
    min-height: 55px;
    min-width: 112px;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: all .3s ease-in-out 0s;
}

.work .projects .project_item .project_item_detail .button_wrapper .btn::after {
    -webkit-animation: ring 1.5s infinite;
    animation: ring 1.5s infinite;
    border: 6px solid #14e956;
    border-radius: 7px;
    content: "";
    height: 30px;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 30px;
    z-index: -1;
}

.work .projects .project_item .project_item_detail .button_wrapper .btn:hover,
.work .projects .project_item .project_item_detail .button_wrapper .btn:focus {
    transform: translateY(-6px);
}


.work .projects .project_item .project_item_detail .button_wrapper .btn:hover::before,
.work .projects .project_item .project_item_detail .button_wrapper .btn:focus::before {
    opacity: 1;
}


.work .projects .project_item .project_item_detail .button_wrapper .btn:hover::after,
.work .projects .project_item .project_item_detail .button_wrapper .btn:focus::after {
    animation: none;
    display: none;
}