h1 {
    font-size: 3.5rem;
    line-height: 60px;
}

h2 {
    font-size: 2.8rem;
    line-height: 48px;
}

.home {
    width: 100%;
    max-width: 100%;
    will-change: transform;
    transform: translate3d(0, 0, 0);
}

/* first section styling  */
.home .home-intro {
    align-items: center;
    background-image: linear-gradient(to right bottom, rgba(5, 8, 16, .6), rgba(5, 8, 16, .6)), url(../IMAGES/home_bg.svg);
    background-position: top;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    padding: 100px 100px;
}


@media (min-width: 760px) and (max-width: 1024px) {
    .home .home-intro {
        flex-direction: column;
        padding: 0 2rem;
        padding-top: 8rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .home-intro {
        flex-direction: column;
        padding: 0 1.2rem;
        padding-top: 6rem;
    }
}

@media (max-width: 479px) {
    .home .home-intro {
        flex-direction: column;
        padding: 0 0.5rem;
        padding-top: 4rem;
        text-align: center;
    }
}

.home .home-intro.hidden {
    opacity: 0;
}

.home .home-intro.show {
    opacity: 1;
}

.home .home-intro.show .content {
    animation: fadeInLeft 1s ease forwards;
}

.home .home-intro.show .hero-icon {
    animation: fadeInRight 1s ease forwards;
}

.home .home-intro.show .content {
    -webkit-animation: fadeInLeft 1s ease forwards;
    animation: fadeInLeft 1s ease forwards;
    will-change: transform;
}

.home .home-intro .content {
    margin-right: 6rem;
    width: 55%;
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .home-intro .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 0rem;
    }
}


@media (min-width: 480px) and (max-width: 759px) {
    .home .home-intro .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 2rem;
        margin-right: 0rem;
    }
}

@media (max-width: 479px) {
    .home .home-intro .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0rem;
    }
}

.home .home-intro .content .first_content {
    margin-bottom: 1.5rem;
    width: -webkit-max-content;
    width: max-content;
}

.home .home-intro .content .first_content p {
    background: rgba(20, 233, 88, .157);
    border-radius: 12px;
    color: #14e956;
    font-size: 1.4rem;
    font-weight: 900;
    letter-spacing: .05em;
    margin: 0;
    padding: .5rem 1rem;
    text-transform: capitalize;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .home-intro .content .first_content p {
        font-size: 1.2rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .home-intro .content .first_content p {
        font-size: 1rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .home-intro .content .first_content p {
        font-size: 0.85rem;
        padding: 0.27rem 0.7rem;
        display: flex;
        align-items: center;
    }
}

@media (max-width: 479px) {
    .home .home-intro .content .first_content p {
        font-size: 0.75rem;
        padding: 0.2rem 0.5rem;
        display: flex;
        align-items: center;
    }
}

.home .home-intro .content .first_content p span {
    -webkit-animation: wobble 2.5s infinite;
    animation: wobble 2.5s infinite;
    display: inline-block;
    font-size: 1.7rem;
}

.home .home-intro .content .content-tertiary p span {
    font-size: 1.7rem;
    animation: wobble 2.5s infinite;
    display: inline-block;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .home-intro .content .first_content p span {
        font-size: 1.5rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .home-intro .content .first_content p span {
        font-size: 1.2rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .home-intro .content .first_content p span {
        font-size: 1rem;
    }
}

@media (max-width: 479px) {
    .home .home-intro .content .first_content p span {
        font-size: 0.9rem;
    }
}

.home .home-intro .content .second_content {
    margin-bottom: 1.5rem;
}

.home .home-intro .content .second_content h1 {
    font-weight: 900;
    letter-spacing: .02em;
    margin: 0;
    text-transform: capitalize;
    font-family: Exospace;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .home-intro .content .second_content h1 {
        font-size: 3.2rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .home-intro .content .second_content h1 {
        font-size: 2.9rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .home-intro .content .second_content h1 {
        font-size: 2.6rem;
    }
}

@media (max-width: 479px) {
    .home .home-intro .content .second_content h1 {
        font-size: 2.3rem;
    }
}

.home .home-intro .content .second_content h1 span {
    color: #14e956;
    font-family: Cyborg;
    font-size: 4.2rem;
    text-shadow: 3px 3px 0 #1f2127, -1px -1px 0 #1f2127, 1px -1px 0 #1f2127, -1px 1px 0 #1f2127, 1px 1px 0 #1f2127;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .home-intro .content .second_content h1 span {
        font-size: 3.9rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .home-intro .content .second_content h1 span {
        font-size: 3.4rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .home-intro .content .second_content h1 span {
        font-size: 3rem;
    }
}

@media (max-width: 479px) {
    .home .home-intro .content .second_content h1 span {
        font-size: 2.6rem;
    }
}

.home .home-intro .content .third_content p {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: .04em;
    line-height: 30px;
    margin: 0;
    opacity: .8;
    text-transform: lowercase;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .home-intro .content .third_content p {
        font-size: 16px;
        line-height: 26px;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .home-intro .content .third_content p {
        font-size: 15px;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .home-intro .content .third_content p {
        font-size: 14px;
        line-height: 21px;
    }
}

@media (max-width: 479px) {
    .home .home-intro .content .third_content p {
        font-size: 13px;
        line-height: 20px;
    }
}

.home .home-intro.show .hero_icon {
    -webkit-animation: fadeInRight 2s ease forwards;
    animation: fadeInRight 2s ease forwards;
}

.home .home-intro .hero_icon {
    padding-bottom: 1rem;
    width: 45%;
    transition: transform 0.5s ease;
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .home-intro .hero_icon {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .home-intro .hero_icon {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 2rem;
    }
}

@media (max-width: 479px) {
    .home .home-intro .hero_icon {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 3rem;
    }
}

.home .home-intro .hero_icon svg {
    height: 366px;
    width: 500px;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .home-intro .hero_icon svg {
        width: 450px;
        height: 329px;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .home-intro .hero_icon svg {
        width: 430px;
        height: 314px;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .home-intro .hero_icon svg {
        width: 400px;
        height: 292px;
    }
}

@media (max-width: 479px) {
    .home .home-intro .hero_icon svg {
        width: 300px;
        height: 219px;
    }
}

/* second section styling  */
.home .tooling {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
}

.home .tooling.hidden {
    opacity: 0;
}

.home .tooling.show {
    opacity: 1;
}

.home .tooling.show .tooling_title {
    -webkit-animation: flipInY 2s ease forwards;
    animation: flipInY 2s ease forwards;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
}

.home .tooling.show .tooling_box {
    animation: jackInTheBox 2s ease forwards;
}

.home .tooling .tooling_title {
    background: #12141d;
    border-radius: 12px;
    padding: .8rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    box-shadow: 0px 0px 4px 1px rgba(20, 233, 86, 1) inset;
    -webkit-box-shadow: 0px 0px 4px 1px rgba(20, 233, 86, 1) inset;
    -moz-box-shadow: 0px 0px 4px 1px rgba(20, 233, 86, 1) inset;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .tooling .tooling_title {
        padding: 0.7rem 1.7rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .tooling .tooling_title {
        padding: 0.6rem 1.5rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .tooling .tooling_title {
        padding: 0.5rem 1.3rem;
    }
}

@media (max-width: 479px) {
    .home .tooling .tooling_title {
        padding: 0.4rem 1.1rem;
    }
}

@supports (-webkit-text-stroke:0.1px #000) {
    .home .tooling .tooling_title h2 {
        -webkit-text-stroke: .1px #000;
        -webkit-text-fill-color: #14e956;
    }
}

.home .tooling .tooling_title h2 {
    color: #14e956;
    font-family: Cyborg;
    font-weight: 900;
    text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    text-transform: uppercase;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .tooling .tooling_title h2 {
        font-size: 2.5rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .tooling .tooling_title h2 {
        font-size: 2.2rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .tooling .tooling_title h2 {
        font-size: 2rem;
    }
}

@media (max-width: 479px) {
    .home .tooling .tooling_title h2 {
        font-size: 1.8rem;
    }
}


.home .tooling .tooling_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background: rgba(12, 140, 58, 1);
    border-radius: 15px;
    width: 50%;
    padding: .5rem;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .tooling .tooling_box {
        padding: .4rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .tooling .tooling_box {
        padding: .3rem;
        width: 70%;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .tooling .tooling_box {
        width: 70%;
    }
}

@media (max-width: 479px) {
    .home .tooling .tooling_box {
        width: 80%;
    }
}

.home .tooling .tooling_box .box_content {
    flex: 0 18%;
    height: 60px;
    margin: 1%;
    border-radius: 8px;
    background: rgba(2, 23, 9, 1);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .tooling .tooling_box .box_content {
        flex: 0 30%;
    }
}

@media (max-width: 479px) {
    .home .tooling .tooling_box .box_content {
        flex: 0 30%;
        height: 50px;
    }
}

.home .tooling .tooling_box .box_content .box_icon .icon {
    width: 30px;
    height: 30px;
    color: #0ea33c;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .tooling .tooling_box .box_content .box_icon .icon {
        height: 27px;
        width: 27px;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .tooling .tooling_box .box_content .box_icon .icon {
        width: 25px;
        height: 25px;
    }
}

@media (max-width: 479px) {
    .home .tooling .tooling_box .box_content .box_icon .icon {
        width: 23px;
        height: 23px;
    }
}

.home .tooling .tooling_box .box_content .box_text p {
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .tooling .tooling_box .box_content .box_text p {
        font-size: 16px;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .tooling .tooling_box .box_content .box_text p {
        font-size: 16px;
    }
}

@media (max-width: 479px) {
    .home .tooling .tooling_box .box_content .box_text p {
        font-size: 12px;
    }
}

/* third section styling  */
.home .showroom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
}



.home .showroom.hidden {
    opacity: 0;
}

.home .showroom.show {
    opacity: 1;
}

.home .showroom.show .showroom_title {
    -webkit-animation: flipInY 2s ease forwards;
    animation: flipInY 2s ease forwards;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
}

.home .showroom .showroom_title {
    background: #12141d;
    border-radius: 12px;
    padding: .8rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    box-shadow: 0px 0px 4px 1px rgba(20, 233, 86, 1) inset;
    -webkit-box-shadow: 0px 0px 4px 1px rgba(20, 233, 86, 1) inset;
    -moz-box-shadow: 0px 0px 4px 1px rgba(20, 233, 86, 1) inset;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .showroom .showroom_title {
        padding: .7rem 1.7rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .showroom .showroom_title {
        padding: .6rem 1.5rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .showroom_title {
        padding: .5rem 1.3rem;
    }
}

@media (max-width: 479px) {
    .home .showroom .showroom_title {
        padding: .4rem 1.1rem;
    }
}

@supports (-webkit-text-stroke:0.1px #000) {
    .home .showroom .showroom_title h2 {
        -webkit-text-stroke: .1px #000;
        -webkit-text-fill-color: #14e956;
    }
}

.home .showroom .showroom_title h2 {
    color: #14e956;
    font-family: Cyborg;
    font-weight: 900;
    text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    text-transform: uppercase;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .showroom .showroom_title h2 {
        font-size: 2.5rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .showroom .showroom_title h2 {
        font-size: 2.2rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .showroom_title h2 {
        font-size: 2rem;
    }
}

@media (max-width: 479px) {
    .home .showroom .showroom_title h2 {
        font-size: 1.8rem;
    }
}

.home .showroom.show .showroom_box {
    -webkit-animation: fadeInLeft 2s ease forwards;
    animation: fadeInLeft 2s ease forwards;
}

.home .showroom .showroom_box {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 90vh;
    justify-content: center;
    position: relative;
    width: 100%;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .showroom .showroom_box {
        height: 100vh;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .showroom .showroom_box {
        height: 90vh;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .showroom_box {
        height: 100vh;
    }
}

@media (max-width: 479px) {
    .home .showroom .showroom_box {
        height: 70vh;
    }
}

.home .showroom .showroom_box .showroom_background {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    height: 100%;
    object-fit: contain;
    width: 100%;
}

.home .showroom .showroom_box .slider {
    align-items: center;
    background-color: transparent;
    display: flex;
    height: 80%;
    justify-content: center;
    left: 50%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
}

.home .showroom .showroom_box .slider .left_arrow {
    color: #000;
    cursor: pointer;
    font-size: 3rem;
    height: 88px;
    left: 32px;
    opacity: .9;
    position: absolute;
    top: 50%;
    -webkit-transform: rotate(.6turn);
    transform: rotate(.6turn);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 90px;
    z-index: 10;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .showroom .showroom_box .slider .left_arrow {
        width: 80px;
        height: 78px;
        top: 42%;
        left: 62px;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .showroom .showroom_box .slider .left_arrow {
        width: 70px;
        height: 68px;
        top: 42%;
        left: 62px;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .showroom_box .slider .left_arrow {
        width: 60px;
        height: 58px;
        top: 42%;
        left: 50px;
    }
}

@media (max-width: 479px) {
    .home .showroom .showroom_box .slider .left_arrow {
        width: 45px;
        height: 40px;
        top: 42%;
        left: 9px;
    }
}



.home .showroom .showroom_box .slider .left_arrow:focus,
.home .showroom .showroom_box .slider .left_arrow:hover {
    -webkit-transform: rotate(.6turn) scale(1.1);
    transform: rotate(.6turn) scale(1.1);
}

.home .showroom .showroom_box .slider .right_arrow:focus,
.home .showroom .showroom_box .slider .right_arrow:hover {
    -webkit-transform: rotate(-36deg) scale(1.1);
    transform: rotate(-36deg) scale(1.1);
}

.home .showroom .showroom_box .slider .right_arrow {
    color: #000;
    cursor: pointer;
    font-size: 3rem;
    height: 88px;
    opacity: .9;
    position: absolute;
    right: 32px;
    top: 50%;
    -webkit-transform: rotate(-36deg);
    transform: rotate(-36deg);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 90px;
    z-index: 10;
}


@media (min-width: 1025px) and (max-width: 1300px) {
    .home .showroom .showroom_box .slider .right_arrow {
        width: 80px;
        height: 78px;
        top: 42%;
        right: 62px;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {

    .home .showroom .showroom_box .slider .right_arrow {
        width: 70px;
        height: 68px;
        top: 42%;
        right: 62px;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .showroom_box .slider .right_arrow {
        width: 60px;
        height: 58px;
        top: 42%;
        right: 50px;
    }
}

@media (max-width: 479px) {
    .home .showroom .showroom_box .slider .right_arrow {
        width: 45px;
        height: 40px;
        top: 42%;
        right: 9px;
    }

}



.home .showroom .showroom_box .slider .slide {
    position: absolute;
    top: 2%;
    right: 26%;
    /* transform: translate(-50%, -50%); */
    opacity: 0;
    width: 700px;
    height: 70%;
    background: #fdfdfd;
    color: #1e2128;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition-duration: 1s;
    transition-timing-function: ease;
    box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.6);
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .showroom .showroom_box .slider .slide {
        width: 600px;
        top: 5%;
        right: auto;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {

    .home .showroom .showroom_box .slider .slide {
        width: 500px;
        top: 8%;
        right: auto;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .showroom_box .slider .slide {
        width: 300px;
        top: 15%;
        right: auto;
    }
}

@media (max-width: 479px) {
    .home .showroom .showroom_box .slider .slide {
        width: 240px;
        top: 15%;
        right: auto;
    }
}


.home .showroom .showroom_box .slider .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}

.home .showroom .showroom_box .slider .slide .image {
    border-radius: 10px;
    height: 398px;
    width: 700px;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .showroom .showroom_box .slider .slide .image {
        width: 600px;
        height: 341px;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .showroom .showroom_box .slider .slide .image {
        width: 500px;
        height: 284px;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .showroom_box .slider .slide .image {
        width: 300px;
        height: 170px;
    }
}

@media (max-width: 479px) {
    .home .showroom .showroom_box .slider .slide .image {
        width: 240px;
        height: 136px;
    }
}

.home .showroom .showroom_box .slider .slide .detail {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0;
    padding: 1rem;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .showroom .showroom_box .slider .slide .detail {
        padding: .8rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .showroom .showroom_box .slider .slide .detail {
        padding: .6rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .showroom_box .slider .slide .detail {
        padding: .5rem;
    }
}

@media (max-width: 479px) {
    .home .showroom .showroom_box .slider .slide .detail {
        padding: .4rem;
    }
}

.home .showroom .showroom_box .slider .slide .detail h4 {
    color: #14e956;
    font-family: Cyborg;
    font-size: 1.8rem;
    font-weight: 900;
    margin: 0 0 .5rem;
    text-shadow: 3px 3px 0 #1f2127, -1px -1px 0 #1f2127, 1px -1px 0 #1f2127, -1px 1px 0 #1f2127, 1px 1px 0 #1f2127;
    text-transform: uppercase;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .showroom .showroom_box .slider .slide .detail h4 {
        font-size: 1.5rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .showroom .showroom_box .slider .slide .detail h4 {
        font-size: 1.2rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .showroom_box .slider .slide .detail h4 {
        font-size: 1.1rem;
    }
}

@media (max-width: 479px) {
    .home .showroom .showroom_box .slider .slide .detail h4 {
        font-size: 1rem;
    }
}

.home .showroom .showroom_box .slider .slide .detail .desc {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 .5rem;
    text-transform: capitalize;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .showroom .showroom_box .slider .slide .detail .desc {
        font-size: 16px;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .showroom .showroom_box .slider .slide .detail .desc {
        font-size: 14px;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .showroom_box .slider .slide .detail .desc {
        font-size: 12px;
    }
}

@media (max-width: 479px) {
    .home .showroom .showroom_box .slider .slide .detail .desc {
        font-size: 11px;
    }
}

.home .showroom .showroom_box .slider .slide .detail span {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 25%;
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .showroom .showroom_box .slider .slide .detail span {
        width: 33%;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .showroom_box .slider .slide .detail span {
        width: 50%;
    }
}

@media (max-width: 479px) {
    .home .showroom .showroom_box .slider .slide .detail span {
        width: 70%;
    }
}

.home .showroom .showroom_box .slider .slide .detail span .stack {
    background: #1e2128;
    border-radius: 25px;
    color: #fffff4;
    font-weight: 700;
    margin: 0;
    padding: .3rem .6rem;
    text-transform: lowercase;
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .showroom .showroom_box .slider .slide .detail span .stack {
        font-size: 14px;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .showroom_box .slider .slide .detail span .stack {
        font-size: 13px;
    }
}

@media (max-width: 479px) {
    .home .showroom .showroom_box .slider .slide .detail span .stack {
        font-size: 12px;
    }
}

.home .showroom .showroom_box .slider .slide .detail span a {
    background: #fffff4;
    border: 2px solid #1e2128;
    border-radius: 15px;
    color: #1e2128;
    font-weight: 700;
    margin: 0;
    padding: .1rem .6rem;
    text-transform: lowercase;
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .showroom .showroom_box .slider .slide .detail span a {
        font-size: 14px;
    }
}

@media (max-width: 479px) {
    .home .showroom .showroom_box .slider .slide .detail span a {
        font-size: 12px;
    }
}

.home .showroom .button_wrapper {
    align-items: center;
    display: flex;
    height: 20vh;
    justify-content: center;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .showroom .button_wrapper {
        height: 16vh;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .showroom .button_wrapper {
        height: 14vh;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .button_wrapper {
        height: 12vh;
    }
}

@media (max-width: 479px) {
    .home .showroom .button_wrapper {
        height: 11vh;
    }
}

.home .showroom .button_wrapper .btn {
    background: #14e956;
    background-color: #14e956;
    background-image: linear-gradient(315deg, #14e956, #72f29a);
    border: none;
    border-radius: 7px;
    box-shadow: 9px 7px 32px -11px rgba(20, 233, 86, .74);
    cursor: pointer;
    font-family: Cyborg;
    font-size: 2.2rem;
    font-weight: 900;
    outline: none;
    padding: 1rem 3rem;
    position: relative;
    text-transform: uppercase;
    transition: all .3s ease-in-out 0s;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .showroom .button_wrapper .btn {
        padding: .7rem 2.3rem;
        font-size: 1.9rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .showroom .button_wrapper .btn {
        padding: .6rem 1.9rem;
        font-size: 1.5rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .button_wrapper .btn {
        padding: .5rem 1.5rem;
        font-size: 1.2rem;
    }
}

@media (max-width: 479px) {
    .home .showroom .button_wrapper .btn {
        padding: .4rem 1.3rem;
        font-size: .9rem;
    }
}


.home .showroom .button_wrapper .btn::before {
    content: '';
    border-radius: 7px;
    width: calc(450px + 12px);
    height: calc(80px + 12px);
    border: 6px solid #72f29a;
    box-shadow: 0 0 60px rgba(20, 233, 86, 0.64);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease-in-out 0s;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .showroom .button_wrapper .btn::before {
        width: calc(400px + 12px);
        height: calc(70px + 12px);
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .showroom .button_wrapper .btn::before {
        width: calc(340px + 12px);
        height: calc(50px + 12px);
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .button_wrapper .btn::before {
        width: calc(300px + 12px);
        height: calc(40px + 12px);
    }
}

@media (max-width: 479px) {
    .home .showroom .button_wrapper .btn::before {
        width: calc(280px + 12px);
        height: calc(35px + 12px);
    }
}

.home .showroom .button_wrapper .btn::after {
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 7px;
    border: 6px solid #14e956;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .showroom .button_wrapper .btn::after {
        width: 26px;
        height: 26px;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .showroom .button_wrapper .btn::after {
        width: 24px;
        height: 24px;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .showroom .button_wrapper .btn::after {
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 479px) {
    .home .showroom .button_wrapper .btn::after {
        width: 19px;
        height: 19px;
    }
}

.home .showroom .button_wrapper .btn:hover,
.home .showroom .button_wrapper .btn:focus {
    transform: translateY(-6px);
}

.home .showroom .button_wrapper .btn:hover::before,
.home .showroom .button_wrapper .btn:focus::before {
    opacity: 1;
}

.home .showroom .button_wrapper .btn:hover::after,
.home .showroom .button_wrapper .btn:focus::after {
    animation: none;
    display: none;
}

.home .quote {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10rem;
    margin-top: 10rem;
    position: relative;
}

.home .quote.hidden {
    opacity: 0;
}

.home .quote.show {
    opacity: 1;
}

.home .quote .quote_body {
    grid-column-gap: 0;
    grid-row-gap: 0;
    align-content: center;
    align-items: center;
    background-color: transparent;
    background-image: url(../IMAGES/quote_bg.png);
    border-radius: 15px;
    box-shadow: 0 0 1px 3px rgba(20, 233, 86, .98);
    -webkit-box-shadow: 0 0 1px 3px rgba(20, 233, 86, .98);
    -moz-box-shadow: 0 0 1px 3px rgba(20, 233, 86, .98);
    display: grid;
    grid-template-areas:
        "left . . ."
        ". quote quote ."
        ". quote quote ."
        ". . . right";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    height: 200px;
    justify-items: center;
    position: relative;
    width: 60%;
    z-index: 10;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .quote .quote_body {
        height: 190px;
        width: 65%;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .quote .quote_body {
        height: 180px;
        width: 70%;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .quote .quote_body {
        height: 170px;
        width: 80%;
    }
}

@media (max-width: 479px) {
    .home .quote .quote_body {
        height: 160px;
        width: 87%;
    }
}

.home .quote.show .quote_body {
    -webkit-animation: zoomIn 1.5s ease forwards;
    animation: zoomIn 1.5s ease forwards;
}

.home .quote_body .left {
    grid-area: left;
    height: 100px;
    margin-top: -2.4rem;
    width: 100px;
    z-index: 100;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .quote_body .left {
        width: 90px;
        height: 90px;
        margin-top: -2.2rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .quote_body .left {
        width: 80px;
        height: 80px;
        margin-top: -2rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .quote_body .left {
        width: 70px;
        height: 70px;
        margin-top: -1.8rem;
    }
}

@media (max-width: 479px) {
    .home .quote_body .left {
        width: 60px;
        height: 60px;
        margin-top: -1.6rem;
    }
}

.home .quote_body .right {
    grid-area: right;
    height: 100px;
    margin-bottom: -2.4rem;
    width: 100px;
    z-index: 100;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .quote_body .right {
        width: 90px;
        height: 90px;
        margin-bottom: -2.2rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .quote_body .right {
        width: 80px;
        height: 80px;
        margin-bottom: -2rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .quote_body .right {
        width: 70px;
        height: 70px;
        margin-bottom: -1.8rem;
    }
}

@media (max-width: 479px) {
    .home .quote_body .right {
        width: 60px;
        height: 60px;
        margin-bottom: -1.6rem;
    }
}

.home .quote_body .quote_body_content {
    grid-area: quote;
}

.home .quote_body .quote_body_content p {
    font-size: 2.5rem;
    font-weight: 900;
    margin: 0;
    opacity: .8;
    text-transform: capitalize;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .quote_body .quote_body_content p {
        font-size: 2.3rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .quote_body .quote_body_content p {
        font-size: 2rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .quote_body .quote_body_content p {
        font-size: 1.6rem;
    }
}

@media (max-width: 479px) {
    .home .quote_body .quote_body_content p {
        font-size: 1.2rem;
    }
}

.home .quote_body .quote_body_content p span {
    font-size: 3rem;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .home .quote_body .quote_body_content p span {
        font-size: 2.6rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    .home .quote_body .quote_body_content p span {
        font-size: 2.3rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    .home .quote_body .quote_body_content p span {
        font-size: 2rem;
    }
}

@media (max-width: 479px) {
    .home .quote_body .quote_body_content p span {
        font-size: 1.7rem;
    }
}

/* Animations  */
@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes wobble {
    0% {
        -webkit-transform: translateZ(0) scale(1);
        transform: translateZ(0) scale(1);
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate(-5deg) scale(1.1);
        transform: translate3d(-25%, 0, 0) rotate(-5deg) scale(1.1);
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate(3deg) scale(1.2);
        transform: translate3d(20%, 0, 0) rotate(3deg) scale(1.2);
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate(-3deg) scale(1.3);
        transform: translate3d(-15%, 0, 0) rotate(-3deg) scale(1.3);
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate(2deg) scale(1.2);
        transform: translate3d(10%, 0, 0) rotate(2deg) scale(1.2);
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate(-1deg) scale(1.1);
        transform: translate3d(-5%, 0, 0) rotate(-1deg) scale(1.1);
    }

    100% {
        -webkit-transform: translateZ(0) scale(1);
        transform: translateZ(0) scale(1);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes flipInY {
    0% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
        -webkit-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
    }

    40% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: perspective(400px) rotateY(-20deg);
        transform: perspective(400px) rotateY(-20deg);
    }

    60% {
        opacity: 1;
        -webkit-transform: perspective(400px) rotateY(10deg);
        transform: perspective(400px) rotateY(10deg);
    }

    80% {
        -webkit-transform: perspective(400px) rotateY(-5deg);
        transform: perspective(400px) rotateY(-5deg);
    }

    100% {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@keyframes jackInTheBox {
    0% {
        opacity: 0;
        -webkit-transform: scale(.1) rotate(30deg);
        transform: scale(.1) rotate(30deg);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
    }

    50% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    70% {
        -webkit-transform: rotate(3deg);
        transform: rotate(3deg);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes ring {
    0% {
        height: 30px;
        opacity: 1;
        width: 30px;
    }

    100% {
        height: 120px;
        opacity: 0;
        width: 550px;
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }
}