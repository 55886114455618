@font-face {
  font-family: 'ExoSpace';
  src: url(./assets/FONTS/exospace.ttf) format('truetype');
}

/* Works on Firefox test*/
* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 13px;
}

*::-webkit-scrollbar-track {
  background: #e8fdee;
}

*::-webkit-scrollbar-thumb {
  background-color: #14e956;
  border-radius: 20px;
  border: 2px solid #fdfdfd;

}

@font-face {
  font-family: 'Cyborg';
  src: url('./assets/FONTS/cyborg.ttf') format('truetype');
}

:root {
  --navlink-font-size: 4.25rem;
}

#root {
  width: 100%;
}

body,
html {
  cursor: url(./assets/IMAGES/cursor.svg) 15 15, move;
  height: 100%;
  width: 100%;
    overflow-x: hidden;
}

@media (min-width: 760px) and (max-width: 1024px) {

  body,
  html {
    max-width: 100%;
    max-height: 100%;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #050810;
  scroll-behavior: smooth;
  color: #f5f5f5;
  font-family: Exospace;
}

a[href],
button,
input[type=checkbox],
input[type=image],
input[type=submit],
label[for],
select {
  cursor: url(./assets/IMAGES/link_cursor.svg) 15 15, move !important;
}


@media (max-width: 600px) {
  body {
    overflow-x: hidden !important;
  }
}