footer {
    width: 100%;
    overflow-x: hidden;
}

footer .social {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5rem;
    padding: 0;
    width: 100%;
}



footer .social .social_title {
    align-self: center;
    margin-bottom: 4.5rem;
    -webkit-transform: rotate(3.6deg);
    transform: rotate(3.6deg);
}

@media (min-width: 1025px) and (max-width: 1300px) {
    footer .social .social_title {
        margin-bottom: 4.3rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_title {
        margin-bottom: 4rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_title {
        margin-bottom: 3.6rem;
    }
}

@media (max-width: 479px) {
    footer .social .social_title {
        margin-bottom: 3.3rem;
    }
}

@supports (-webkit-text-stroke:0.4px #000) {
    footer .social .social_title h2 {
        -webkit-text-stroke: .4px #000;
        -webkit-text-fill-color: #14e956;
    }
}

footer .social .social_title h2 {
    color: #14e956;
    font-family: Cyborg;
    font-size: 3rem;
    font-weight: 900;
    margin: 0;
    opacity: .9;
    text-shadow: 3px 3px 0 #14e956, -1px -1px 0 #14e956, 1px -1px 0 #14e956, -1px 1px 0 #14e956, 1px 1px 0 #14e956;
    text-transform: uppercase;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    footer .social .social_title h2 {
        font-size: 2.5rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_title h2 {
        font-size: 2.2rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_title h2 {
        font-size: 2rem;
    }
}

@media (max-width: 479px) {
    footer .social .social_title h2 {
        font-size: 1.8rem;
    }
}

footer .social .social_body {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_body {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_body {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (max-width: 479px) {
    footer .social .social_body {
        flex-wrap: wrap;
        justify-content: center;
    }
}

footer .social .social_body .github_box {
    align-items: center;
    background: #333;
    color: #d6d6d6;
    cursor: pointer;
    display: flex;
    height: 9rem;
    justify-content: space-between;
    padding: 0 1.5rem;
    position: relative;
    width: 25%;
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_body .github_box {
        width: 50%;
        padding: 0;
        justify-content: space-around;
        height: 6rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_body .github_box {
        width: 100%;
        padding: 0 3rem;
        justify-content: space-between;
        height: 5rem;
    }
}

@media (max-width: 479px) {
    footer .social .social_body .github_box {
        width: 100%;
        padding: 0 1.5rem;
        justify-content: space-between;
        height: 4.5rem;
    }
}

footer .social .social_body .github_box p {
    font-size: 3.5rem;
    font-weight: 600;
    margin: 0;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    footer .social .social_body .github_box p {
        font-size: 3.1rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_body .github_box p {
        font-size: 2.9rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_body .github_box p {
        font-size: 2.6rem;
    }
}

@media (max-width: 479px) {
    footer .social .social_body .github_box p {
        font-size: 2.1rem;
    }
}

footer .social .social_body .github_box svg {
    height: 85px;
    width: 85px;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    footer .social .social_body .github_box svg {
        width: 75px;
        height: 75px;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_body .github_box svg {
        width: 65px;
        height: 65px;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_body .github_box svg {
        width: 55px;
        height: 55px;
    }
}

@media (max-width: 479px) {
    footer .social .social_body .github_box svg {
        width: 45px;
        height: 45px;
    }
}

footer .social .social_body .linkedin_box {
    align-items: center;
    background: #0077b5;
    color: #cce4f0;
    cursor: pointer;
    display: flex;
    height: 9rem;
    justify-content: space-between;
    padding: 0 1.5rem;
    width: 25%
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_body .linkedin_box {
        width: 50%;
        padding: 0;
        justify-content: space-around;
        height: 6rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_body .linkedin_box {
        width: 100%;
        padding: 0 3rem;
        justify-content: space-between;
        height: 5rem;
    }
}

@media (max-width: 479px) {
    footer .social .social_body .linkedin_box {
        width: 100%;
        padding: 0 1.5rem;
        justify-content: space-between;
        height: 4.5rem;
    }
}

footer .social .social_body .linkedin_box p {
    font-size: 3.5rem;
    font-weight: 600;
    margin: 0;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    footer .social .social_body .linkedin_box p {
        font-size: 3.1rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_body .linkedin_box p {
        font-size: 2.9rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_body .linkedin_box p {
        font-size: 2.6rem;
    }
}

@media (max-width: 479px) {
    footer .social .social_body .linkedin_box p {
        font-size: 2.1rem;
    }
}

footer .social .social_body .linkedin_box svg {
    height: 85px;
    width: 85px;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    footer .social .social_body .linkedin_box svg {
        width: 75px;
        height: 75px;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_body .linkedin_box svg {
        width: 65px;
        height: 65px;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_body .linkedin_box svg {
        width: 55px;
        height: 55px;
    }
}

@media (max-width: 479px) {
    footer .social .social_body .linkedin_box svg {
        width: 45px;
        height: 45px;
    }
}

footer .social .social_body .twitter_box {
    align-items: center;
    background: #1da1f2;
    color: #d2ecfc;
    cursor: pointer;
    display: flex;
    height: 9rem;
    justify-content: space-between;
    padding: 0 1.5rem;
    width: 25%;
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_body .twitter_box {
        width: 50%;
        padding: 0;
        justify-content: space-around;
        height: 6rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_body .twitter_box {
        width: 100%;
        padding: 0 3rem;
        justify-content: space-between;
        height: 5rem;
    }
}

@media (max-width: 479px) {
    footer .social .social_body .twitter_box {
        width: 100%;
        padding: 0 1.5rem;
        justify-content: space-between;
        height: 4.5rem;
    }
}

footer .social .social_body .twitter_box p {
    font-size: 3.5rem;
    font-weight: 600;
    margin: 0;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    footer .social .social_body .twitter_box p {
        font-size: 3.1rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_body .twitter_box p {
        font-size: 2.9rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_body .twitter_box p {
        font-size: 2.6rem;
    }
}

@media (max-width: 479px) {
    footer .social .social_body .twitter_box p {
        font-size: 2.1rem;
    }
}

footer .social .social_body .twitter_box svg {
    height: 85px;
    width: 85px;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    footer .social .social_body .twitter_box svg {
        width: 75px;
        height: 75px;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_body .twitter_box svg {
        width: 65px;
        height: 65px;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_body .twitter_box svg {
        width: 55px;
        height: 55px;
    }
}

@media (max-width: 479px) {
    footer .social .social_body .twitter_box svg {
        width: 45px;
        height: 45px;
    }
}

footer .social .social_body .calendly_box {
    align-items: center;
    background: #006cff;
    color: #cce2ff;
    cursor: pointer;
    display: flex;
    height: 9rem;
    justify-content: space-between;
    padding: 0 1.5rem;
    width: 25%;
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_body .calendly_box {
        width: 50%;
        padding: 0;
        justify-content: space-around;
        height: 6rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_body .calendly_box {
        width: 100%;
        padding: 0 3rem;
        justify-content: space-between;
        height: 5rem;
    }
}

@media (max-width: 479px) {
    footer .social .social_body .calendly_box {
        width: 100%;
        padding: 0 1.5rem;
        justify-content: space-between;
        height: 4.5rem;
    }
}

footer .social .social_body .calendly_box p {
    font-size: 3.5rem;
    font-weight: 600;
    margin: 0;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    footer .social .social_body .calendly_box p {
        font-size: 3.1rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_body .calendly_box p {
        font-size: 2.9rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_body .calendly_box p {
        font-size: 2.6rem;
    }
}

@media (max-width: 479px) {
    footer .social .social_body .calendly_box p {
        font-size: 2.1rem;
    }
}

footer .social .social_body .calendly_box svg {
    height: 85px;
    width: 85px;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    footer .social .social_body .calendly_box svg {
        width: 75px;
        height: 75px;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_body .calendly_box svg {
        width: 65px;
        height: 65px;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_body .calendly_box svg {
        width: 55px;
        height: 55px;
    }
}

@media (max-width: 479px) {
    footer .social .social_body .calendly_box svg {
        width: 45px;
        height: 45px;
    }
}

footer .social .social_mail {
    align-items: center;
    background: #10ba46;
    cursor: pointer;
    display: flex;
    height: 4rem;
    justify-content: center;
    width: 100%;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    footer .social .social_mail {
        height: 4rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_mail {
        height: 3.7rem;
    }
}

@media (max-width: 479px) {
    footer .social .social_mail {
        height: 3.5rem;
    }
}

footer .social .social_mail .mail_box {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 23%;
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_mail .mail_box {
        width: 50%;
    }
}

@media (max-width: 479px) {
    footer .social .social_mail .mail_box {
        width: 100%;
        padding: 0 1.5rem;
    }
}

footer .social .social_mail .mail_box p {
    color: #d0fbdd;
    font-family: Cyborg;
    font-size: 2.2rem;
    font-weight: 900;
    margin: 0 0 -.3rem;
    text-transform: uppercase;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    footer .social .social_mail .mail_box p {
        font-size: 1.8rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_mail .mail_box p {
        font-size: 1.4rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_mail .mail_box p {
        font-size: 1.7rem;
    }
}

@media (max-width: 479px) {
    footer .social .social_mail .mail_box p {
        font-size: 1.5rem;
    }
}

footer .social .social_mail .mail_box .mail {
    height: 50px;
    margin: -.3rem 0 0;
    width: 50px;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    footer .social .social_mail .mail_box .mail {
        height: 40px;
        width: 40px;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .social .social_mail .mail_box .mail {
        height: 30px;
        width: 30px;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .social .social_mail .mail_box .mail {
        height: 38px;
        width: 38px;
    }
}

@media (max-width: 479px) {
    footer .social .social_mail .mail_box .mail {
        height: 40px;
        width: 40px;
    }
}

footer .footer {
    align-items: center;
    background: #1f2128;
    display: flex;
    height: -webkit-max-content;
    /* height: max-content; */
    min-height: 100px;
    justify-content: center;
    width: 100%;
}

footer .footer p {
    color: #10ba45;
    font-family: Cyborg;
    font-size: 1.4rem;
    font-weight: 900;
    margin: 0;
    padding: 2rem 0;
    text-transform: capitalize;
}

@media (min-width: 1025px) and (max-width: 1300px) {
    footer .footer p {
        font-size: 1.2rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    footer .footer p {
        font-size: 1.1rem;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    footer .footer p {
        font-size: .9rem;
        text-align: center;
        padding: .9rem .3rem;
    }
}

@media (max-width: 479px) {
    footer .footer p {
        font-size: .9rem;
        text-align: center;
        padding: .7rem .2rem;
    }
}

footer .footer p span {
    animation: heartBeat 2.5s infinite;
    display: inline-block;
}


@keyframes heartBeat {
    0% {
      transform: scale(1);
    }
  
    14% {
      transform: scale(1.3);
    }
  
    28% {
      transform: scale(1);
    }
  
    42% {
      transform: scale(1.3);
    }
  
    70% {
      transform: scale(1);
    }
  }