header {
    max-width: 100%;
    animation: fadeInDown 1s ease forwards;
    background-color: #10e956 !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
}


header .header_wrapper {
    padding: 5px 20px;
    height: 100%;
}

header .header_wrapper .logo img {
    max-width: 70px;
    border-radius: 8px;
}


header .header_wrapper .nav_list_container .nav_button {
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 45px;
    justify-content: center;
    width: 60px;
    right: 2rem;
    top: 15px;
    position: fixed;
    z-index: 2000;
    animation: wobble 1s ease forwards 10s;
}

header .header_wrapper .nav_button .nav_icon {
    background-color: #1e2128;
    border-radius: 4px;
    display: inline-block;
    height: 8px;
    margin-right: -.8rem;
    position: relative;
    -webkit-transform: rotate(.5turn);
    transform: rotate(.5turn);
    width: 2.7rem;
}


header .header_wrapper .nav_button .nav_icon::before {
    content: "";
    left: 0;
    position: absolute;
    transition: all .2s;
    background-color: #1e2128;
    border-radius: 4px;
    display: inline-block;
    height: 8px;
    width: 2rem;
    top: -.8rem;
}

header .header_wrapper .nav_button .nav_icon::after {
    background-color: #1e2128;
    border-radius: 4px;
    display: inline-block;
    height: 8px;
    width: 3.5rem;
    content: "";
    left: 0;
    position: absolute;
    transition: all .2s;
    top: .8rem;
}

header .header_wrapper .nav_list_container .nav_active .nav_icon::before {
    top: 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    width: 3.5rem;
}

header .header_wrapper .nav_list_container .nav_active .nav_icon::after {
    top: 0;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    width: 3.5rem;
}

header .header_wrapper .nav_list_container .nav_active .nav_icon {
    background-color: transparent;
}

header .menu {
    display: none;
}

header .header_wrapper .menu_active {
    background: #14e956;
    width: 100% !important;
    height: 100vh !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

header .header_wrapper .menu_active .header_socials {
    background: #1e2128;
    display: flex;
    grid-area: box;
    height: 100%;
    width: 100%;
}

@media (min-width: 760px) {
    header .header_wrapper .menu_active .header_socials {
        max-width: 50vw;
    }
}

header .header_wrapper .menu_active .header_socials .github {
    align-items: center;
    background: #333;
    color: #d6d6d6;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 25%;
}


.header_socials .github {
    opacity: 0;
}

.menu_active .header_socials .github {
    animation: zoomIn .5s ease forwards;
    opacity: 1;
    transition: opacity .5s;
}

header .header_wrapper .menu_active .header_socials .github .github_box {
    align-items: center;
    display: flex;
    flex-direction: column;
}

header .header_wrapper .menu_active .header_socials .github .github_box svg {
    height: 85px;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    width: 85px;
}

header .header_wrapper .menu_active .header_socials .github .github_box p {
    font-size: 5.5rem;
    font-weight: 600;
    padding: 5rem;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}

header .header_wrapper .menu_active .header_socials .linkedin {
    align-items: center;
    background: #0077b5;
    color: #cce4f0;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    width: 25%;
}

.header_socials .linkedin {
    opacity: 0;
}

.menu_active .header_socials .linkedin {
    animation: zoomIn .5s ease forwards;
    opacity: 1;
    transition: opacity .5s;
}

header .header_wrapper .menu_active .header_socials .linkedin .linkedin_box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

header .header_wrapper .menu_active .header_socials .linkedin .linkedin_box svg {
    height: 85px;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    width: 85px;
}

header .header_wrapper .menu_active .header_socials .linkedin .linkedin_box p {
    font-size: 5.5rem;
    font-weight: 600;
    padding: 6rem;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}

header .header_wrapper .menu_active .header_socials .twitter {
    align-items: center;
    background: #1da1f2;
    color: #d2ecfc;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    width: 25%;
}

.header_socials .twitter {
    opacity: 0;
}

.menu_active .header_socials .twitter {
    -webkit-animation: zoomIn .5s ease forwards;
    animation: zoomIn .5s ease forwards;
    opacity: 1;
    transition: opacity .5s;
}

header .header_wrapper .menu_active .header_socials .twitter .twitter_box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

header .header_wrapper .menu_active .header_socials .twitter .twitter_box svg {
    height: 85px;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    width: 85px;
}

header .header_wrapper .menu_active .header_socials .twitter .twitter_box p {
    font-size: 5.5rem;
    font-weight: 600;
    padding: 5rem;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}

header .header_wrapper .menu_active .header_socials .schedule {
    align-items: center;
    background: #006cff;
    color: #cce2ff;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    width: 25%;
}

.header_socials .schedule {
    opacity: 0;
}

.menu_active .header_socials .schedule {
    -webkit-animation: zoomIn .5s ease forwards;
    animation: zoomIn .5s ease forwards;
    opacity: 1;
    transition: opacity .5s;
}

header .header_wrapper .menu_active .header_socials .schedule .schedule_box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

header .header_wrapper .menu_active .header_socials .schedule .schedule_box svg {
    height: 85px;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    width: 85px;
}

header .header_wrapper .menu_active .header_socials .schedule .schedule_box p {
    font-size: 5.5rem;
    font-weight: 600;
    padding: 6rem;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}

header .header_wrapper .menu_active .menu_list {
    align-items: center;
    background: #1e2128;
    display: flex;
    grid-area: menu;
    height: 75%;
    justify-content: flex-end;
    text-align: right;
    width: 50%;
}

header .header_wrapper .menu_active .menu_list ul {
    padding-right: 30px;
}

header .header_wrapper .menu_active .menu_list ul li {
    list-style: none;
    background: transparent;
    margin-top: 20px;
}

header .header_wrapper .menu_active .menu_list ul li a {
    color: #f5f5f5;
    font-size: var(--navlink-font-size);
    font-weight: 900;
    letter-spacing: 3px;
    text-decoration: none;
    text-shadow: 3px 3px 0 #14e956, -1px -1px 0 #14e956, 1px -1px 0 #14e956, -1px 1px 0 #14e956, 1px 1px 0 #14e956;
    text-transform: uppercase;
}

header .header_wrapper .menu_active .menu_list ul li .active::before,
header .header_wrapper .menu_active .menu_list ul li a:hover::before {
    content: " ";
    display: inline-block;
    background-color: transparent;
    background-image: url('../IMAGES/active_link_hand.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 60px;
    height: 60px;
    margin-right: 2rem;
}


@supports (-webkit-text-stroke:0.1px #14e956) {
    header .header_wrapper .menu_active .menu_list ul li a {
        -webkit-text-stroke: .1px #14e956;
        -webkit-text-fill-color: transparent;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes wobble {
    from {
        transform: translate3d(0, 0, 0);
    }

    15% {
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }

    30% {
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    45% {
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    60% {
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }

    75% {
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

/* responsiveness  */
@media (min-width: 760px) and (max-width: 1024px) {
    header {
        max-width: 100%;
    }
}

@media (min-width: 480px) and (max-width: 759px) {
    header {
        max-width: 100%;
    }
}

@media (max-width: 479px) {
    header {
        max-width: 100%;
    }

}

@media (min-width: 760px) and (max-width: 1024px) {
    header .header_wrapper .menu_active .menu_list ul li a {
        font-size: 3rem;
    }
}


@media (min-width: 480px) and (max-width: 759px) {
    header .header_wrapper .menu_active .menu_list ul li a {
        font-size: 2.1rem;
        letter-spacing: 4px;
        text-shadow:
            3px 3px 0 #12d24d,
            -1px -1px 0 #12d24d,
            1px -1px 0 #12d24d,
            -1px 1px 0 #12d24d,
            1px 1px 0 #12d24d;

        @supports (-webkit-text-stroke: .1px #14e956) {
            -webkit-text-stroke: .1px #14e956;
            -webkit-text-fill-color: transparent;
        }
    }
}


@media (min-width: 1025px) and (max-width: 1300px) {
    header .header_wrapper .menu_active .header_socials .github .github_box p {
        font-size: 5.2rem;
        padding: 4.5rem;
    }


    header .header_wrapper .menu_active .header_socials .github .github_box svg {
        width: 80px;
        height: 80px;
    }

    header .header_wrapper .menu_active .header_socials .linkedin .linkedin_box p {
        font-size: 5.2rem;
        padding: 5rem;
    }

    header .header_wrapper .menu_active .header_socials .linkedin .linkedin_box svg {
        width: 80px;
        height: 80px;
    }

    header .header_wrapper .menu_active .header_socials .twitter .twitter_box p {
        font-size: 5.2rem;
        padding: 4.5rem;
    }

    header .header_wrapper .menu_active .header_socials .twitter .twitter_box svg {
        width: 80px;
        height: 80px;
    }

    header .header_wrapper .menu_active .header_socials .schedule .schedule_box p {
        font-size: 5.2rem;
        padding: 5rem;
    }

    header .header_wrapper .menu_active .header_socials .schedule .schedule_box svg {
        width: 80px;
        height: 80px;
    }

    header .header_wrapper .menu_active .menu_list ul li .active::before,
    header .header_wrapper .menu_active .menu_list ul li a:hover::before {
        width: 52px;
        height: 52px;
        margin-right: 1.7rem;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    header .header_wrapper .menu_active .header_socials .github .github_box p {
        font-size: 4.6rem;
        padding-right: 2rem;
    }


    header .header_wrapper .menu_active .header_socials .github .github_box svg {
        width: 70px;
        height: 70px;
    }

    header .header_wrapper .menu_active .header_socials .linkedin .linkedin_box p {
        font-size: 4.6rem;
        padding: 4.5rem;
    }

    header .header_wrapper .menu_active .header_socials .linkedin .linkedin_box svg {
        width: 70px;
        height: 70px;
    }

    header .header_wrapper .menu_active .header_socials .twitter .twitter_box p {
        font-size: 4.6rem;
        padding: 4rem;
    }

    header .header_wrapper .menu_active .header_socials .twitter .twitter_box svg {
        width: 70px;
        height: 70px;
    }

    header .header_wrapper .menu_active .header_socials .schedule .schedule_box p {
        font-size: 4.6rem;
        padding: 4.5rem;
    }

    header .header_wrapper .menu_active .header_socials .schedule .schedule_box svg {
        width: 70px;
        height: 70px;
    }

    header .header_wrapper .menu_active .menu_list ul li .active::before,
    header .header_wrapper .menu_active .menu_list ul li a:hover::before {
        width: 43px;
        height: 43px;
        margin-right: 1.4rem;
    }

    header .header_wrapper .nav_list_container .nav_button {
        width: 42px;
        height: 32px;
    }

}

@media (min-width: 480px) and (max-width: 759px) {
    header .header_wrapper .menu_active .header_socials .github .github_box p {
        font-size: 3rem;
        padding: 1.4rem;
        -webkit-transform: rotate(0turn);
        transform: rotate(0turn);
    }

    header .header_wrapper .menu_active .header_socials .github .github_box svg {
        transform: rotate(0turn);
        width: 60px;
        height: 60px;
    }

    header .header_wrapper .menu_active .header_socials .linkedin .linkedin_box p {
        font-size: 3rem;
        padding: 1.4rem;
        -webkit-transform: rotate(0turn);
        transform: rotate(0turn);
    }

    header .header_wrapper .menu_active .header_socials .linkedin .linkedin_box svg {
        transform: rotate(0turn);
        width: 60px;
        height: 60px;
    }

    header .header_wrapper .menu_active .header_socials .twitter .twitter_box p {
        font-size: 3rem;
        padding: 1.4rem;
        -webkit-transform: rotate(0turn);
        transform: rotate(0turn);
    }

    header .header_wrapper .menu_active .header_socials .twitter .twitter_box svg {
        transform: rotate(0turn);
        width: 60px;
        height: 60px;

    }

    header .header_wrapper .menu_active .header_socials .schedule .schedule_box p {
        font-size: 3rem;
        padding: 1.4rem;
        -webkit-transform: rotate(0turn);
        transform: rotate(0turn);
    }

    header .header_wrapper .menu_active .header_socials .schedule .schedule_box svg {
        transform: rotate(0turn);
        width: 60px;
        height: 60px;
    }

    header .header_wrapper .menu_active {
        flex-direction: column;
    }

    header .header_wrapper .menu_active .header_socials {
        width: 100vw !important;
        display: block;
        order: 2;
    }

    header .header_wrapper .menu_active .header_socials .github {
        width: 100%;
        height: 5rem;
    }

    header .header_wrapper .menu_active .header_socials .github .github_box {
        flex-direction: row;
    }

    header .header_wrapper .menu_active .header_socials .linkedin {
        width: 100%;
        height: 5rem;
    }

    header .header_wrapper .menu_active .header_socials .linkedin .linkedin_box {
        flex-direction: row;
    }


    header .header_wrapper .menu_active .header_socials .linkedin {
        width: 100%;
        height: 5rem;
    }

    header .header_wrapper .menu_active .header_socials .linkedin .linkedin_box {
        flex-direction: row;
    }

    header .header_wrapper .menu_active .header_socials .twitter {
        width: 100%;
        height: 5rem;
    }

    header .header_wrapper .menu_active .header_socials .twitter .twitter_box {
        flex-direction: row;
    }

    header .header_wrapper .menu_active .header_socials .schedule {
        width: 100%;
        height: 5rem;
    }

    header .header_wrapper .menu_active .header_socials .schedule .schedule_box {
        flex-direction: row;
    }

    header .header_wrapper .menu_active .menu_list {
        width: 100%;
        justify-content: center;
    }

    header .header_wrapper .menu_active .menu_list ul li .active::before,
    header .header_wrapper .menu_active .menu_list ul li a:hover::before {
        width: 34px;
        height: 34px;
        margin-right: 1.2rem;
    }

    header .header_wrapper .menu_active .menu_list ul {
        padding-right: 0px;
        margin: 20px 0px;
    }

    header .header_wrapper .nav_list_container .nav_button {
        width: 40px;
        height: 30px;
    }

    header .header_wrapper .nav_list_container .nav_active .nav_icon::before {
        background-color: #10e956;
        width: 3rem;
    }

    header .header_wrapper .nav_list_container .nav_active .nav_icon::after {
        background-color: #10e956;
        width: 3rem;
    }
}


@media (max-width: 479px) {
    header .header_wrapper .menu_active .menu_list ul li a {
        font-size: 2rem;
        letter-spacing: 4px;
        text-shadow:
            3px 3px 0 #12d24d,
            -1px -1px 0 #12d24d,
            1px -1px 0 #12d24d,
            -1px 1px 0 #12d24d,
            1px 1px 0 #12d24d;

        @supports (-webkit-text-stroke: .1px #14e956) {
            -webkit-text-stroke: .1px #14e956;
            -webkit-text-fill-color: transparent;
        }
    }

    header .header_wrapper .menu_active .header_socials .github .github_box p {
        font-size: 2rem;
        padding: 1.4rem;
        -webkit-transform: rotate(0turn);
        transform: rotate(0turn);
    }

    header .header_wrapper .menu_active .header_socials .github .github_box svg {
        transform: rotate(0turn);
        width: 50px;
        height: 50px;
    }

    header .header_wrapper .menu_active .header_socials .linkedin .linkedin_box p {
        font-size: 2rem;
        padding: 1rem;
        -webkit-transform: rotate(0turn);
        transform: rotate(0turn);
    }

    header .header_wrapper .menu_active .header_socials .linkedin .linkedin_box svg {
        transform: rotate(0turn);
        width: 50px;
        height: 50px;
    }

    header .header_wrapper .menu_active .header_socials .twitter .twitter_box p {
        font-size: 2rem;
        padding: 1.4rem;
        -webkit-transform: rotate(0turn);
        transform: rotate(0turn);
    }

    header .header_wrapper .menu_active .header_socials .twitter .twitter_box svg {
        transform: rotate(0turn);
        width: 50px;
        height: 50px;
    }

    header .header_wrapper .menu_active .header_socials .schedule .schedule_box p {
        font-size: 2rem;
        padding: 1rem;
        -webkit-transform: rotate(0turn);
        transform: rotate(0turn);
    }

    header .header_wrapper .menu_active .header_socials .schedule .schedule_box svg {
        transform: rotate(0turn);
        width: 50px;
        height: 50px;
    }

    header .header_wrapper .menu_active {
        flex-direction: column;
    }

    header .header_wrapper .menu_active .header_socials {
        width: 100vw !important;
        display: block;
        order: 2;
    }

    header .header_wrapper .menu_active .header_socials .github {
        width: 100%;
        height: 5rem;
    }

    header .header_wrapper .menu_active .header_socials .github .github_box {
        flex-direction: row;
    }

    header .header_wrapper .menu_active .header_socials .linkedin {
        width: 100%;
        height: 5rem;
    }

    header .header_wrapper .menu_active .header_socials .linkedin .linkedin_box {
        flex-direction: row;
    }


    header .header_wrapper .menu_active .header_socials .linkedin {
        width: 100%;
        height: 5rem;
    }

    header .header_wrapper .menu_active .header_socials .linkedin .linkedin_box {
        flex-direction: row;
    }

    header .header_wrapper .menu_active .header_socials .twitter {
        width: 100%;
        height: 5rem;
    }

    header .header_wrapper .menu_active .header_socials .twitter .twitter_box {
        flex-direction: row;
    }

    header .header_wrapper .menu_active .header_socials .schedule {
        width: 100%;
        height: 5rem;
    }

    header .header_wrapper .menu_active .header_socials .schedule .schedule_box {
        flex-direction: row;
    }

    header .header_wrapper .menu_active .menu_list {
        width: 100%;
        justify-content: center;
    }

    header .header_wrapper .menu_active .menu_list ul li .active::before,
    header .header_wrapper .menu_active .menu_list ul li a:hover::before {
        width: 34px;
        height: 34px;
        margin-right: 1.2rem;
    }

    header .header_wrapper .menu_active .menu_list ul {
        padding-right: 0px;
        margin: 20px 0px;
    }

    header .header_wrapper .nav_list_container .nav_button {
        width: 40px;
        height: 30px;
    }

    header .header_wrapper .nav_list_container .nav_active .nav_icon::before {
        background-color: #10e956;
        width: 3rem;
    }

    header .header_wrapper .nav_list_container .nav_active .nav_icon::after {
        background-color: #10e956;
        width: 3rem;
    }

    header .header_wrapper .nav_list_container  .nav_icon::after {
        width: 2.5rem;
        height: 6px;
        top: .7rem;
    }
      
    header .header_wrapper .nav_button .nav_icon {
        width: 2rem;
        height: 6px;
    }   

    header .header_wrapper .nav_list_container  .nav_icon::before {
        width: 1.6rem;
        height: 6px;
        top: -.7rem;
    }
 
}